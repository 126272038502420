var _sort_type;
$(document).ready(function () {
    $('#sub-tab a').click(function (e) {
        e.preventDefault();
        $(this).tab('show');
    });
    $('#sub-tab a:first').tab('show');
    $("#book-tab li").click(function() {
        var library = $(this).attr("book");
        $("#kw").val("");
        load_tab((library));
    });
    select_tab($("#tab_name").val());
    _sort_type = $("#tab_sort_type").val();
    select_sort_type(_sort_type);
    init_page();
	$("#search-book-btn").click(function() {
	    var tab = $("#book-tab li.active").attr("book");
        load_tab(tab, 1);
    });
    $('#kw').keyup(function(e) {
        if (e.keyCode == 13) {
            var tab = $("#book-tab li.active").attr("book");
            load_tab(tab, 1);
        }
    });
    $(document).on('change', 'input:radio[name="sort_type"]', function (event) {
       sort_tab($(this).val());
    });
});

function select_tab(tabname) {
    $("#book-tab li").removeClass("active");
    $("#book-tab li").each(function() {
        if($(this).attr("book") == tabname) {
            $(this).addClass("active");
        }
    });
}

function select_sort_type(tab_sort_type) {
    $(".sort-type-group label").removeClass("active");
    $(".sort-type-group label").each(function() {
        if($(this).attr("sort_type") == tab_sort_type) {
            $(this).addClass("active");
        }
    });
    $("input[name=sort_type]").each(function() {
        if($(this).val() == tab_sort_type) {
            $(this).checked = true;
        }
    });
}

function load_tab(tab, page) {
    select_tab(tab);
    var tab_clicked = false;
    if (!page) {
        page = 1;
        tab_clicked = true;
    }
    if(!_sort_type) {
    }
    var kw = $("#kw").val();
    $.post("/tab/"+tab, {
        "page": page,
        "sort_type": _sort_type,
        "kw":kw
    }, function(data){
        $("#books").html(data);
        init_page();
        if (!tab_clicked) {
            $('html, body').scrollTop($('#book-container').offset().top);
        }
    }, "html");
}

function sort_tab(sort_type) {
    _sort_type = sort_type;
    var tab = $("#book-tab li.active").attr("book");
    var kw = $("#kw").val();
    $.post("/tab/"+tab, {
        "sort_type": _sort_type,
        "kw": kw,
        "page":1
    }, function(data){
        $("#books").html(data);
        init_page();
    }, "html");
}

function init_page() {
    var total_count = $("#total_count").val();
    var current_page_index = $("#current_page").val()-1;
    $("#pagination").pagination(total_count, {
		callback:handlePaginationClick,
		current_page:current_page_index,
		items_per_page:$("#per_page").val(),
		num_display_entries:5,
		num_edge_entries:1,
		prev_text: '이전',
		next_text: '다음'
	});
}

function handlePaginationClick(new_page_index, pagination_container) {
    var nextpage = new_page_index+1;
    var tab = $("#book-tab li.active").attr("book");
    load_tab(tab, nextpage);
    return false;
}

document.addEventListener("DOMContentLoaded", function() {
    var panels = document.querySelectorAll('.card-panel');
    var maxHeight = 0;

    // 모든 패널의 높이를 순회하며 최대 높이 찾기
    panels.forEach(function(panel) {
        if (panel.offsetHeight > maxHeight) {
            maxHeight = panel.offsetHeight;
        }
    });
    
    // 모든 패널의 높이를 최대 높이로 설정
    panels.forEach(function(panel) {
        panel.style.height = maxHeight + "px";
    });
});